<template>
  <div class="mb-6 mx-4">
    <div class="background">
      <div class="row">
        <div class="mx-0 pa-0 logo">
          <img src="@/assets/sze_logo.png" class="logo" />
        </div>
        <!-- <div
          v-if="title == null || title == undefined"
          class="pa-0 ma-0 text-center d-flex align-end"
        ></div>
        <div
          class="ma-auto pa-0"
          v-if="title_2 != null || title_2 != undefined"
        >
          <p lang="hu" class="header_p">
            {{ title_2 }}
          </p>
        </div>
        <div
          v-if="title != null || title != undefined"
          class="pa-0 ma-0 text-center d-flex align-end"
        >
          <p lang="hu" class="header_p">
            {{ title }}
          </p>
        </div> -->
        <div v-if="title_ne != null || title_ne != undefined" class="mx-auto pa-0">
          <p class="header_p">
            <b>{{ title_ne }}: </b>{{ title }}
          </p>
          <p class="header_p">
            <b>{{ title_na }}: </b>{{ title_2 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
import { mapState } from "vuex";
export default {
  props: {
    title: String,
    title_2: String,
    title_na: String,
    title_ne: String,
  },
  mounted() {
  },
};
</script>

<style >
.background {
  width: 80%;
  margin: auto;
  display: flex;
}
.fontsize {
  font-size: 1.5rem;
}
.logo {
  width: min(50vw, 400px);
}
.row {
  display: flex;
}
.header_p {
  font-size: 1.1rem;
  font-weight: 200;
  margin: 0 !important;
  padding: 0;
}
</style>