<template>
  <div>
    <Header :title="title" />
    <v-card elevation="2" class="mx-auto header-row" width="80%">
      <v-row class="ma-0 pa-0">
        <v-col :cols="12" :lg="8" :md="8" :sm="12" class="d-flex align-center"
          ><p class="py-0 pl-3 my-0 header-text text-white text-uppercase">
            {{ title }}
          </p></v-col
        >
        <v-col :cols="12" :lg="4" :md="4" :sm="12">
          <v-btn
            @click="languageChange('hu')"
            class="ml-0 mr-3 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >hu</v-btn
          >
          <v-btn
            @click="languageChange('en')"
            class="mr-1 ml-3 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >en</v-btn
          >
          <v-tooltip bottom class="float-right m-auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                outlined
                dark
                class="float-right p-0 mx-3"
                v-bind="attrs"
                v-on="on"
                @click="excelExport"
              >
                <v-icon class="p-0 m-0">mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Excel export</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      min-height="800"
      elevation="0"
      width="80%"
      class="mx-auto mt-5 mb-5 bg-transparent"
    >
      <v-card class="mx-0 pa-5">
        <v-overlay :value="overlay">
          <v-progress-circular
            indeterminate
            size="250"
            width="10"
            color="uni_blue2"
          ></v-progress-circular>
        </v-overlay>

        <div v-html="description" class="scroll mt-5"></div>
        <p v-if="megjegyzesek.length > 0">
          <strong>{{ lang.megjegyzes }}</strong>
        </p>
        <ul v-if="megjegyzesek.length > 0">
          <li v-for="(meg, megId) in information.megjegyzesek" :key="megId">
            {{ meg.megjegyzes }}
          </li>
        </ul>
      </v-card>
      <v-card
        :outlined="false"
        class="mx-auto bg-transparent my-0"
        elevation="0"
        v-if="title != 'Általános információ' && !overlay"
      >
        <v-card
          class="pa-5 my-6"
          v-for="(modul, modulKey) in modulok"
          :key="modulKey"
        >
          <div
            v-if="
              modul.modul_lathato == true &&
              modul.mintaterv.types[0].subjects.length > 0
            "
          >
            <div class="modulbg rounded px-3">
              <p v-if="$cookies.get('lang') == 'hu'">
                <b>{{ lang.kar }}:</b> {{ information.kar }}
              </p>
              <p v-else>
                <b>{{ lang.kar }}:</b> {{ information.kar_en }}
              </p>
              <p v-if="$cookies.get('lang') == 'hu'">
                <b>{{ lang.szak }}:</b> {{ information.szak }}
              </p>
              <p v-else>
                <b>{{ lang.szak }}:</b> {{ information.szak_en }}
              </p>
              <p v-if="$cookies.get('lang') == 'hu'">
                <b>{{ lang.modul_nev }}:</b> {{ modul.modul_nev }}
              </p>
              <p v-else>
                <b>{{ lang.modul_nev }}:</b> {{ modul.modul_nev_en }}
              </p>
              <p class="information" v-if="$cookies.get('lang') == 'hu'">
                <b>{{ lang.mintaterv }}:</b> {{ modul.mintaterv.F5 }}
              </p>
              <p class="information" v-else>
                <b>{{ lang.mintaterv }}:</b> {{ modul.mintaterv.F18 }}
              </p>
            </div>
            <v-row class="m-0 p-0">
              <v-col
                :cols="12"
                :lg="12"
                :md="12"
                :sm="12"
                v-for="(type, typeKey) in modul.mintaterv.types"
                :key="typeKey"
                class="m-0 p-0"
              >
                <v-divider class="mx-4"></v-divider>
                <p>
                  <b>{{ type.name ? type.name : "" }}</b>
                </p>
                <p>{{ type.leiras }}</p>
                <v-simple-table class="border" v-if="type.children.length == 0">
                  <template>
                    <thead>
                      <tr class="uni_blue2">
                        <th class="p-2 text-light border">
                          {{ lang.table_sorszam }}
                        </th>
                        <th class="p-2 text-light border">
                          {{ lang.table_kod }}
                        </th>
                        <th class="p-2 text-light border">
                          {{ lang.table_megnevezes }}
                        </th>
                        <th
                          class="p-2 text-light border"
                          v-if="samplePlan.type != 'Levelező'"
                        >
                          {{ lang.table_heti_elmelet }}
                        </th>
                        <th class="p-2 text-light border" v-else>
                          {{ lang.table_feleves_elmelet }}
                        </th>
                        <th
                          class="p-2 text-light border"
                          v-if="samplePlan.type != 'Levelező'"
                        >
                          {{ lang.table_heti_gyakorlat }}
                        </th>
                        <th class="p-2 text-light border" v-else>
                          {{ lang.table_feleves_gyakorlat }}
                        </th>
                        <th
                          class="p-2 text-light border"
                          v-if="samplePlan.type != 'Levelező'"
                        >
                          {{ lang.table_heti_labor }}
                        </th>
                        <th class="p-2 text-light border" v-else>
                          {{ lang.table_feleves_labor }}
                        </th>
                        <th class="p-2 text-light border">
                          {{ lang.table_beszamolas_fajtaja }}
                        </th>
                        <th class="p-2 text-light border">
                          {{ lang.table_kreditpont }}
                        </th>
                        <th class="p-2 text-light border">
                          {{ lang.table_ajanlott_felev }}
                        </th>
                        <th class="p-2 text-light border">
                          {{ lang.table_elotanulmanyi_kov }}
                        </th>
                        <th class="p-2 text-light border">
                          {{ lang.table_adminisztrativ }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(subject, index) in type.subjects"
                        :key="index"
                      >
                        <td class="px-2 border">{{ index + 1 }}</td>
                        <td class="px-2 border">
                          <a
                            href="javascript:void(0)"
                            @click="tantargytematika(subject.Targykod)"
                            style="color: #0c3c60"
                            >{{ subject.Targykod }}</a
                          >
                        </td>
                        <td class="px-2 border">{{ subject.Targynev }}</td>
                        <td class="px-2 border" v-if="subject.F5 != null">
                          {{ subject.F5 }}
                        </td>
                        <td class="px-2 border" v-if="subject.F22 != null">
                          {{ subject.F22 }}
                        </td>
                        <td
                          class="px-2 border"
                          v-if="subject.F22 == null && subject.F5 == null"
                        >
                          0
                        </td>
                        <td class="px-2 border" v-if="subject.F7 != null">
                          {{ subject.F7 }}
                        </td>
                        <td class="px-2 border" v-if="subject.F23 != null">
                          {{ subject.F23 }}
                        </td>
                        <td
                          class="px-2 border"
                          v-if="subject.F7 == null && subject.F23 == null"
                        >
                          0
                        </td>
                        <td class="px-2 border" v-if="subject.F18 != null">
                          {{ subject.F18 }}
                        </td>
                        <td class="px-2 border" v-if="subject.F24 != null">
                          {{ subject.F24 }}
                        </td>
                        <td
                          class="px-2 border"
                          v-if="subject.F18 == null && subject.F24 == null"
                        >
                          0
                        </td>
                        <td
                          class="px-2 border"
                          v-if="$cookies.get('lang') == 'hu'"
                        >
                          {{ subject.F27 }}
                        </td>
                        <td class="px-2 border" v-else>
                          {{ subject.beszamolo_en }}
                        </td>
                        <td class="px-2 border">
                          <p v-if="subject.Targy_kredit != null">
                            {{ subject.Targy_kredit }}
                          </p>
                          <p v-if="subject.Targy_kredit == null">0</p>
                        </td>
                        <td class="px-2 border">{{ subject.Felev_szam }}</td>
                        <td class="px-2 border">
                          <p v-if="subject.Elokovetelmeny_leiras_1 != null">
                            {{ subject.Elokovetelmeny_leiras_1 }}
                          </p>

                          <p v-if="subject.Elokovetelmeny_leiras_2 != null">
                            {{ subject.Elokovetelmeny_leiras_2 }}
                          </p>
                        </td>
                        <td v-if="subject.admin.Targykod != ''">
                          <p>
                            {{ lang.table_admin_1 }}
                            {{ subject.admin.Targykod }}
                            {{ lang.table_admin_2 }}
                          </p>
                        </td>
                        <td v-else></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div v-else class="ml-8">
                  <div
                    v-for="(child, childKey) in type.children"
                    :key="childKey"
                  >
                    <p>
                      <b>{{ child.name }}</b>
                    </p>
                    <p>
                      {{ child.leiras }}
                    </p>
                    <v-simple-table
                      class="border"
                      v-if="type.children.length != 0"
                    >
                      <template>
                        <thead>
                          <tr class="uni_blue2">
                            <th class="p-2 text-light border">
                              {{ lang.table_sorszam }}
                            </th>
                            <th class="p-2 text-light border">
                              {{ lang.table_kod }}
                            </th>
                            <th class="p-2 text-light border">
                              {{ lang.table_megnevezes }}
                            </th>
                            <th
                              class="p-2 text-light border"
                              v-if="samplePlan.type != 'Levelező'"
                            >
                              {{ lang.table_heti_elmelet }}
                            </th>
                            <th class="p-2 text-light border" v-else>
                              {{ lang.table_feleves_elmelet }}
                            </th>
                            <th
                              class="p-2 text-light border"
                              v-if="samplePlan.type != 'Levelező'"
                            >
                              {{ lang.table_heti_gyakorlat }}
                            </th>
                            <th class="p-2 text-light border" v-else>
                              {{ lang.table_feleves_gyakorlat }}
                            </th>
                            <th
                              class="p-2 text-light border"
                              v-if="samplePlan.type != 'Levelező'"
                            >
                              {{ lang.table_heti_labor }}
                            </th>
                            <th class="p-2 text-light border" v-else>
                              {{ lang.table_feleves_labor }}
                            </th>
                            <th class="p-2 text-light border">
                              {{ lang.table_beszamolas_fajtaja }}
                            </th>
                            <th class="p-2 text-light border">
                              {{ lang.table_kreditpont }}
                            </th>
                            <th class="p-2 text-light border">
                              {{ lang.table_ajanlott_felev }}
                            </th>
                            <th class="p-2 text-light border">
                              {{ lang.table_elotanulmanyi_kov }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(subjectChild, indexChild) in child.subjects"
                            :key="indexChild"
                          >
                            <td class="px-2 border">{{ indexChild + 1 }}</td>
                            <td class="px-2 border">
                              <a
                                href="javascript:void(0)"
                                @click="tantargytematika(subjectChild.Targykod)"
                                style="color: #0c3c60"
                                >{{ subjectChild.Targykod }}</a
                              >
                            </td>
                            <td class="px-2 border">
                              {{ subjectChild.Targynev }}
                            </td>
                            <td
                              class="px-2 border"
                              v-if="subjectChild.F5 != null"
                            >
                              {{ subjectChild.F5 }}
                            </td>
                            <td
                              class="px-2 border"
                              v-if="subjectChild.F22 != null"
                            >
                              {{ subjectChild.F22 }}
                            </td>
                            <td
                              class="px-2 border"
                              v-if="
                                subjectChild.F22 == null &&
                                subjectChild.F5 == null
                              "
                            >
                              0
                            </td>
                            <td
                              class="px-2 border"
                              v-if="subjectChild.F7 != null"
                            >
                              {{ subjectChild.F7 }}
                            </td>
                            <td
                              class="px-2 border"
                              v-if="subjectChild.F23 != null"
                            >
                              {{ subjectChild.F23 }}
                            </td>
                            <td
                              class="px-2 border"
                              v-if="
                                subjectChild.F7 == null &&
                                subjectChild.F23 == null
                              "
                            >
                              0
                            </td>
                            <td
                              class="px-2 border"
                              v-if="subjectChild.F18 != null"
                            >
                              {{ subjectChild.F18 }}
                            </td>
                            <td
                              class="px-2 border"
                              v-if="subjectChild.F24 != null"
                            >
                              {{ subjectChild.F24 }}
                            </td>
                            <td
                              class="px-2 border"
                              v-if="
                                subjectChild.F18 == null &&
                                subjectChild.F24 == null
                              "
                            >
                              0
                            </td>
                            <td
                              class="px-2 border"
                              v-if="$cookies.get('lang') == 'hu'"
                            >
                              {{ subjectChild.F27 }}
                            </td>
                            <td class="px-2 border" v-else>
                              {{ subjectChild.beszamolo_en }}
                            </td>
                            <td class="px-2 border">
                              <p v-if="subjectChild.Targy_kredit != null">
                                {{ subjectChild.Targy_kredit }}
                              </p>
                              <p v-if="subjectChild.Targy_kredit == null">0</p>
                            </td>
                            <td class="px-2 border">
                              {{ subjectChild.Felev_szam }}
                            </td>
                            <td class="px-2 border">
                              <p
                                v-if="
                                  subjectChild.Elokovetelmeny_leiras_1 != null
                                "
                              >
                                {{ subjectChild.Elokovetelmeny_leiras_1 }}
                              </p>

                              <p
                                v-if="
                                  subjectChild.Elokovetelmeny_leiras_2 != null
                                "
                              >
                                {{ subjectChild.Elokovetelmeny_leiras_2 }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div v-if="child.credit != null && child.credit != ''">
                      <p
                        v-if="
                          !(
                            (child.name ? child.name : '').includes('Idegen') ||
                            (child.name ? child.name : '').includes(
                              'Testnevelés'
                            ) ||
                            (child.name ? child.name : '').includes(
                              'Kötelező tárgyak'
                            ) ||
                            (child.name ? child.name : '').includes(
                              '(kötelező törzsanyag)'
                            ) ||
                            (child.name ? child.name : '').includes(
                              '(Kötelező törzsanyag)'
                            )
                          )
                        "
                      >
                        <b>{{ lang.elvegzett_credit }} {{ child.credit }}</b>
                      </p>
                      <p
                        v-if="
                          (child.name ? child.name : '').includes(
                            'Kötelező tárgyak'
                          ) ||
                          (child.name ? child.name : '').includes(
                            '(kötelező törzsanyag)'
                          ) ||
                          (child.name ? child.name : '').includes(
                            '(Kötelező törzsanyag)'
                          )
                        "
                      >
                        <b
                          >{{ lang.elvegzett_credit_kotelezo }}
                          {{ child.credit }}</b
                        >
                      </p>
                    </div>
                    <p
                      v-if="
                        (child.name ? child.name : '').includes('Testnevelés')
                      "
                    >
                      <b
                        >{{ lang.elvegzett_credit_testneveles }}
                        {{ child.tantargyak_szama }}</b
                      >
                    </p>
                    <p
                      v-if="
                        child.credit == null &&
                        child.tantargyak_szama != null &&
                        !(child.name ? child.name : '').includes('Testnevelés')
                      "
                    >
                      <b
                        >{{ lang.elvegzett_tantargy_csoport }}
                        {{ child.tantargyak_szama }}</b
                      >
                    </p>
                  </div>
                </div>
                <div v-if="type.credit != null && type.credit != ''">
                  <p
                    v-if="
                      !(
                        (type.name ? type.name : '').includes('Idegen') ||
                        (type.name ? type.name : '').includes('Testnevelés') ||
                        (type.name ? type.name : '').includes(
                          'Kötelező tárgyak'
                        ) ||
                        (type.name ? type.name : '').includes(
                          '(kötelező törzsanyag)'
                        ) ||
                        (type.name ? type.name : '').includes(
                          '(Kötelező törzsanyag)'
                        )
                      )
                    "
                  >
                    <b>{{ lang.elvegzett_credit }} {{ type.credit }}</b>
                  </p>
                  <p
                    v-if="
                      (type.name ? type.name : '').includes(
                        'Kötelező tárgyak'
                      ) ||
                      (type.name ? type.name : '').includes(
                        '(kötelező törzsanyag)'
                      ) ||
                      (type.name ? type.name : '').includes(
                        '(Kötelező törzsanyag)'
                      )
                    "
                  >
                    <b
                      >{{ lang.elvegzett_credit_kotelezo }} {{ type.credit }}</b
                    >
                  </p>
                </div>
                <p v-if="(type.name ? type.name : '').includes('Testnevelés')">
                  <b
                    >{{ lang.elvegzett_credit_testneveles }}
                    {{ type.tantargyak_szama }}</b
                  >
                </p>
                <p
                  v-if="
                    type.credit == null &&
                    type.tantargyak_szama != null &&
                    !(type.name ? type.name : '').includes('Testnevelés')
                  "
                >
                  <b
                    >{{ lang.elvegzett_tantargy_csoport }}
                    {{ type.tantargyak_szama }}</b
                  >
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-card>
    </v-card>
    <div class="text-center">
      <v-dialog v-model="dialog" width="60%">
        <v-card>
          <v-card-text>
            <TantargyTematika :subject="subject" :lang="lang" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="sDialog" width="70%">
        <v-card>
          <v-card-text class="m-0 p-0">
            <Sugo />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import TantargyTematika from "@/components/TantargyTematika.vue";
import Header from "@/components/Header.vue";
import Sugo from "@/components/Sugo.vue";
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";

export default {
  name: "TanulmanyiTajekoztato",
  components: {
    TantargyTematika,
    Sugo,
    Header,
  },
  data: () => ({
    title: "",
    sDialog: false,
    lang: hu,
    dialog: false,
    params: "",
    description: "",
    subjects: [],
    subject: {},
    modulok: [],
    overlay: true,
    samplePlan: {},
    megjegyzesek: [],
    information: {},
    infPdf: "",
  }),

  computed: {},

  async mounted() {
    this.overlay = true;

    if (this.$route.params.lang == "en") {
      this.lang = en;
      this.$cookies.set("lang", "en");
      this.title = this.information.szak_en;
    } else {
      this.lang = hu;
      this.$cookies.set("lang", "hu");
      this.title = this.information.szak_en;
    }

    this.params = this.$route.params.id;
    const post = {
      id: this.params,
    };
    try {
      const response = await this.$http.post("/getInformation", post, {
        timeout: 0,
      });
      this.information = response.information;
      this.title = response.information.szak;
      if (this.title == "-") {
        this.title = "Általános információ";
      }
      this.samplePlan = {
        department: response.information.kar,
        faculty: response.information.szak,
      };
      if (this.$cookies.get("lang") == "hu") {
        this.description = response.information.leiras;
        this.title = response.information.szak;
      } else {
        this.description = response.information.leiras_en;
        this.title = response.information.szak_en;
      }
      this.infPdf = response.informationPdf;
      this.megjegyzesek = response.information.megjegyzes;

      this.modulok = response.modulok;
    } catch (e) {
      console.error(e);
    } finally {
      this.overlay = false;
    }
  },
  methods: {
    Login() {},
    sugoDialog() {
      this.sDialog = true;
    },
    async tantargytematika(subjectCode) {
      try {
        const post = {
          sub: subjectCode,
        };
        const response = await this.$http.post("getSubjectDetails/", post);
        this.subject = response.details;
      } catch (e) {
        console.error(e);
      }
      this.dialog = true;
    },
    languageChange(type) {
      if (type == "hu") {
        this.description = this.information.leiras;
        this.title = this.information.szak;
        this.lang = hu;
      } else {
        this.description = this.information.leiras_en;
        this.title = this.information.szak_en;
        this.lang = en;
      }
      this.$cookies.set("lang", type);
    },

    async excelExport() {
      try {
        var post = {
          title: this.information.szak + "_" + this.information.szak_kod,
          lang: this.$cookies.get('lang') == 'hu' ? 'hu' : 'en',
          data: [],
        };
        this.modulok.forEach((element) => {
          const item = {
            modul_level: element.level,
            modul_name: element.modul_nev,
            modul_name_en: element.modul_nev_en,
            modul_kod: element.modul_kod,
            lathato: element.lathato,
            mintaterv: element.mintaterv,
          };
          post.data.push(item);
        });
        const response = await this.$http.post("/tanulmanyiExcel", post);
        const link = document.createElement("a");
        link.href = response.file_name;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", response.file + ".xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);
      }
    },

    async pdfExport(index) {
      try {
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        if (index == 0) {
          if (this.$cookies.get("lang") == "hu") {
            link.href =
              "https://ttr.sze.hu/api/tmp/" + this.infPdf.pdf_hu + ".pdf";
            link.setAttribute("download", this.infPdf.pdf_hu + ".pdf");
          } else {
            link.href =
              "https://ttr.sze.hu/api/tmp/" + this.infPdf.pdf_en + ".pdf";
            link.setAttribute("download", this.infPdf.pdf_en + ".pdf");
          }
        } else {
          if (this.$cookies.get("lang") == "hu") {
            link.href =
              "https://ttr.sze.hu/api/tmp/" + this.information.pdf_hu + ".pdf";
            link.setAttribute("download", this.information.pdf_hu + ".pdf");
          } else {
            link.href =
              "https://ttr.sze.hu/api/tmp/" + this.information.pdf_en + ".pdf";
            link.setAttribute("download", this.information.pdf_en + ".pdf");
          }
        }
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);
      } finally {
        this.overlay = false;
      }
    },
  },
};
</script>

<style scoped >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 25px;
}
.v-data-table td {
  padding: 0 !important;
  text-align: center;
}
.v-icon::hover {
  color: transparent;
}

.v-icon::after {
  color: transparent;
}
.header-row {
  background-color: #0c3c60;
}
.scroll {
  width: max-content;
  max-width: 100%;
  z-index: 5;
}
.modulbg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 1px;
  background-color: #0c3c60;
  color: white;
}
p {
  margin: 0.5rem 0;
}
a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}
a::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 0.05em;
  transform: scaleX(0);
  background-color: #0c3c60;
  transform-origin: bottom right;
}
a:hover:after {
  transform: scale(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-in;
}
.header-row {
  background: linear-gradient(135deg, #00adcb 20%, #0c3c60);
}
.header-text {
  font-size: 1.5rem;
  font-weight: 600;
}
</style>