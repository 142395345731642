<template>
  <div>

    <div class="d-flex justify-end sticky to-header mb-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="uni_blue2"
            width="60"
            height="60"
            small
            class="m-1 sticky"
            v-bind="attrs"
            v-on="on"
            @click="semester_dialog_open"
          >
            <v-icon class="text-white">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Új időszak hozzáadás</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="uni_blue2"
            width="60"
            height="60"
            small
            class="m-1 sticky"
            v-bind="attrs"
            v-on="on"
            @click="beforeSave"
            :disabled="search.faculty == ''"
          >
            <v-icon class="text-white">mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>Mentés</span>
      </v-tooltip>
    </div>
    <p class="display-1 mt-10">Tanulmányi tájékoztató</p>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Mintatanterv létrehozás / szerkesztés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ma-0 pa-0">
            <v-col :cols="12" :lg="2" :md="6" :sm="12">
              <v-autocomplete
                label="Félév"
                color="uni_blue2"
                item-color="uni_blue2"
                :items="semesters"
                return-object
                v-model="search.semester"
                item-text="felev"
                item-value="id"
                @change="semesterChange"
              >
              </v-autocomplete>
            </v-col>

            <v-col :cols="12" :lg="2" :md="6" :sm="12">
              <v-autocomplete
                label="Képzés típus"
                color="uni_blue2"
                item-color="uni_blue2"
                :items="type"
                return-object
                item-value="value"
                item-text="text"
                v-model="search.type"
                @change="typeChange"
                :disabled="search.semester == ''"
              >
              </v-autocomplete>
            </v-col>

            <v-col :cols="12" :lg="2" :md="6" :sm="12">
              <v-autocomplete
                color="uni_blue2"
                item-color="uni_blue2"
                label="Kar"
                v-if="
                  search.type == '' ||
                  (search.type.value != 'Angol' &&
                    search.type.value != 'Általános')
                "
                :items="department"
                return-object
                item-text="F1"
                item-value="F1"
                v-model="search.department"
                @change="departmentChange"
                :disabled="
                  search.type.text == 'Általános információ' ||
                  search.type == ''
                "
              >
              </v-autocomplete>
              <v-autocomplete
                color="uni_blue2"
                item-color="uni_blue2"
                label="Kategória"
                v-if="search.type.value == 'Általános'"
                :items="altalanosKategoriak"
                return-object
                item-value="F1"
                item-text="F1"
                v-model="search.department"
                :disabled="search.type.text == ''"
                @change="departmentChange"
              >
              </v-autocomplete>
              <v-autocomplete
                color="uni_blue2"
                item-color="uni_blue2"
                label="Tanrend"
                v-if="search.type.value == 'Angol'"
                :items="curriculum"
                return-object
                item-value="F1"
                item-text="F1"
                v-model="search.department"
                :disabled="
                  search.type.text == '' ||
                  search.type.text == 'Általános információ'
                "
                @change="curicullumChange"
              >
              </v-autocomplete>
            </v-col>

            <v-col :cols="12" :lg="4" :md="6" :sm="12">
              <v-autocomplete
                color="uni_blue"
                item-color="uni_blue2"
                label="Szak"
                :items="faculty"
                return-object
                item-value="id"
                item-text="F3"
                v-model="search.faculty"
                :disabled="search.department == ''"
              >
                <template v-slot:item="{ item }">
                  <span class="m-1">{{ item.F3 }} </span>
                  <span class="m-1">{{ item.F5 }}</span>
                  <span class="m-1">({{ item.F2 }})</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span>{{ item.F3 }} </span>
                  <span>{{ item.F5 }} </span>
                  <span>({{ item.F2 }})</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col :cols="12" :lg="1" :md="2" :sm="2">
              <v-switch
                label="Látható"
                :disabled="search.semester == '' || search.semester == null"
                color="uni_blue"
                v-model="lathato"
                @change="semesterLathatosag(lathato)"
              ></v-switch>
            </v-col>
            <v-col :cols="12" :lg="1" :md="2" :sm="12" class="py-auto">
              <v-btn
                color="uni_blue"
                class="m-3 text-light"
                @click="searchDescription"
                :disabled="
                  ((search.faculty == '' || search.faculty == null) &&
                    search.type.value != 'Általános') ||
                  (search.type.value == 'Általános' && search.department == '')
                "
              >
                Keresés
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- {{search}} -->
    <v-divider class="mx-4"></v-divider>
    <v-card outlined elevation="2" class="my-3 mx-auto p-3" :loading="loading">
      <!-- <v-card class="m-3 p-4" v-if="seeMessageCard"> -->
      <v-row v-if="DescriptionMessage == '' && seeMessageCard">
        <v-col
          :cols="12"
          :lg="6"
          :md="6"
          :sm="12"
          @drop="onDrop($event, 1)"
          @dragover.prevent
          @dragenter.prevent
        >
          <v-card
            v-show="listOne.length != 0"
            class="m-3 p-2"
            v-for="item in listOne"
            :key="item.title"
            draggable="true"
            @dragstart="startDrag($event, item)"
          >
            {{ item.text }}
          </v-card>
          <v-card
            v-show="listOne.length == 0"
            class="m-3 p-2 text-center"
            disabled
            color="grey"
            dark
          >
            Ide húzza ha az előző év szövegét szeretné
          </v-card>
        </v-col>
        <v-col
          :cols="12"
          :lg="6"
          :md="6"
          :sm="12"
          @drop="onDrop($event, 2)"
          @dragover.prevent
          @dragenter.prevent
        >
          <v-card
            class="m-3 p-2 text-center"
            v-for="item in listTwo"
            :key="item.title"
            draggable="true"
            @dragstart="startDrag($event, item)"
          >
            {{ item.text }}
          </v-card>
        </v-col>
      </v-row>

      <v-alert
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
        v-else
      >
        {{ DescriptionMessage }}
      </v-alert>
      <!-- </v-card> -->
    </v-card>
    <v-card class="">
      <tinymcee
        id="editor_hu"
        v-model="samplePlanContent_hu"
        api-key="pfif8olside1dxofub4lrufkriq0zo9yy9anp6d8kusfkcyb"
        :disabled="editor"
        :init="{
          plugins: 'lists link image table code help wordcount',
          entity_encoding: 'raw',
          height: 800,
        }"
      />
    </v-card>
    <v-card class="my-3">
      <tinymcee
        id="editor_en"
        v-model="samplePlanContent_en"
        api-key="pfif8olside1dxofub4lrufkriq0zo9yy9anp6d8kusfkcyb"
        :disabled="editor"
        :init="{
          plugins: 'lists link image table code help wordcount',
          entity_encoding: 'raw',
          height: 800,
        }"
      />
    </v-card>
    <div class="text-center">
      <v-dialog v-model="saveContentDialog" width="50%" class="scrollbar">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2"
            >Figyelmeztetés</v-card-title
          >
          <v-card-text class="p-3">
            <h2>Biztosan akarja menteni a adott állapotot?</h2>
            <v-alert
              border="bottom"
              class="m-0"
              colored-border
              type="warning"
              elevation="2"
              v-if="alreadyHave == true"
            >
              Ezekkel az adatokkal már lett leírás mentve. Kívánja frissíteni a
              leírást?
            </v-alert>
            <v-textarea
              color="uni_blue"
              label="Módosítási megjegyzés"
              v-if="alreadyHave == true"
              v-model="megjegyzes"
            ></v-textarea>
            <!-- <v-divider class="mx-4"></v-divider> -->
            <!-- {{this.search}} -->
            <!-- <h5>-{{ this.search.semester.felev }}</h5>
            <h5>-{{ this.search.type.text }}</h5>
            <h5>-{{ this.search.department.F1 }}</h5>
            <h5>-{{ this.search.faculty.F3 }}</h5>
            <h5>-{{ this.search.specialization.F7 }}</h5>
            <h5>-{{ this.search.samplePlan.F10 }}</h5> -->
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="uni_blue" class="text-light" @click="saveContent"
              >Igen</v-btn
            >
            <v-btn
              color="uni_blue"
              class="text-light"
              @click="saveContentDialog = false"
              >Nem</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="newSemesterDialog" width="50%" class="scrollbar">
        <v-card>
          <v-card-title class="text-h5">Új időszak generálása</v-card-title>
          <v-card-text class="p-3">
            <v-row>
              <v-autocomplete
                color="uni_blue2"
                item-color="uni_blue2"
                label="Év 1"
                :items="years"
                return-object
                item-text="evek"
                item-value="id"
                v-model="newSemesterYear1"
                @change="newSemesterChange"
                :error-messages="inputErrors.year1"
              >
              </v-autocomplete>
              <v-text-field
                v-model="newSemesterYear2.evek"
                readonly
                color="uni_blue2"
              ></v-text-field>
              <v-autocomplete
                color="uni_blue2"
                item-color="uni_blue2"
                label="Szemeszter"
                :items="semesterTypes"
                return-object
                item-text="text"
                item-value="value"
                v-model="newSemesterType"
                :error-messages="inputErrors.type"
              ></v-autocomplete>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="uni_blue" class="text-light" @click="saveNewSemester"
              >Igen</v-btn
            >
            <v-btn
              color="uni_blue"
              class="text-light"
              @click="newSemesterDialog = false"
              >Nem</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-overlay :value="loadingCircular" opacity="0.9">
      <v-progress-circular
        indeterminate
        size="250"
        width="10"
        color="uni_blue2"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">
      <v-icon class="m-1" v-if="snackbarColor == 'green'"
        >mdi-checkbox-marked-circle</v-icon
      >
      <v-icon class="m-1" v-else>mdi-minus-circle</v-icon>{{ snackbarText }}
    </v-snackbar>
    <!-- </v-card> -->
  </div>
</template>

<script>
import Maker from "@tinymce/tinymce-vue";
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
export default {
  components: {
    tinymcee: Maker,
  },
  data: () => ({
    semesters: [],
    altalanosKategoriak: [
      { F1: "Egyetemi kollégium" },
      { F1: "Hallgatói mobilitás" },
      { F1: "Egyetemi könyvtár" },
      { F1: "Sportolási feltételek" },
    ],
    megjegyzes: "",
    editor: true,
    listItems: [],
    listItems_en: [],
    dialog: false,
    loading: false,
    loadingCircular: true,
    tableSearch: {
      semester: "",
    },
    search: {
      semester: "",
      type: "",
      department: "",
      faculty: "",
    },
    type: [
      { value: "Általános", text: "Általános információ" },
      { value: "Nappali", text: "Nappali képzések" },
      { value: "Levelező", text: "Levelező képzések" },
      { value: "Távoktatás", text: "Távoktatási képzések" },
      { value: "Angol", text: "Angol nyelvű képzések" },
    ],
    curriculum: [{ F1: "Alapképzési szakok" }, { F1: "Mesterképzési szakok" }],
    department: [], //kar
    faculty: [], //szak
    specialization: [], //szakirány
    samplePlan: [], //samplePlan
    samplePlanContent_hu: "",
    samplePlanContent_en: "",
    DescriptionMessage: "", // ez a leírás a tantervhez
    seeMessageCard: true,
    saveContentDialog: false,
    newSemesterDialog: false,
    alreadyHave: false,
    newSemesterYear1: "",
    newSemesterYear2: {
      id: "",
      evek: "",
    },
    newSemesterType: "",
    semesterTypes: [
      { value: 0, text: "ősz" },
      { value: 1, text: "tavasz" },
    ],
    inputErrors: {
      year: "",
      type: "",
    },
    years: [],
    snackbar: false,
    timeout: 2000,
    lathato: false,
    snackbarText: "",
    snackbarColor: "",
  }),
  mounted() {
    this.getsemesters();
    this.getInvisibles();
    setTimeout(() => (this.loadingCircular = false), 250);
  },
  computed: {
    listOne() {
      return this.listItems.filter((item) => item.list === 1);
    },
    listTwo() {
      return this.listItems.filter((item) => item.list === 2);
    },
  },
  methods: {
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.listItems.find((item) => item.id == itemID);
      item.list = list;
      if (item.list == 1) {
        if (this.listItems.filter((item) => item.list === 1).length > 1) {
          this.listItems.forEach((element) => {
            if (element.id != item.id) element.list = 2;
          });
        }
        this.samplePlanContent_hu = item.Description_hu;
        this.samplePlanContent_en = item.Description_en;
      } else {
        this.samplePlanContent_hu = "";
        this.samplePlanContent_en = "";
      }
    },
    newSemesterChange() {
      this.newSemesterYear2.evek = parseInt(this.newSemesterYear1.evek) + 1;
      this.newSemesterYear2.id = parseInt(this.newSemesterYear1.evek) + 1;
    },
    async searchDescription() {
      try {
        this.loading = true;
        this.listItems = [];
        this.listItems_en = [];
        this.samplePlanContent_en = "";
        this.samplePlanContent_hu = "";
        const post = this.search;
        if (this.search.type.value == "Általános") {
          this.search.faculty = { F3: "-", F2: "-" };
        }
        const response = await this.$http.post("/searchDescription", post);
        this.DescriptionMessage = response.message;
        let ids = 0;
        if (response.Description) {
          response.Description.forEach((element) => {
            let felev = this.semesters.filter(
              (item) => item.id === element.felev_id
            )[0];

            var item = {
              id: ids,
              text:
                felev.felev + " " + element.szak + " (" + element.created + ")",
              Description_hu: element.leiras,
              Description_en: element.leiras_en,
              list: 2,
            };
            this.listItems.push(item);
            ids++;
          });
        }

        this.seeMessageCard = true;
        this.editor = false;
      } catch (e) {
        console.error();
      } finally {
        this.loading = false;
      }
    },
    async semesterLathatosag(lathato) {
      try {
        var value = lathato == true ? 1 : 0;
        var post = {
          id: this.search.semester.id,
          value: value,
        };
        const response = await this.$http.post(
          "/changeSemesterLathatosag",
          post
        );
      } catch (e) {
        console.error(e);
      }
    },
    async getInvisibles() {
      try {
        const response = await this.$http.post("/getInvisibles");
        this.invisibleSamplePlans = response.sample_plans;
      } catch (e) {
        console.error(e);
      }
    },
    async getAllFaculty() {
      try {
        const response = await this.$http.post("/getAllFaculty");
        this.allFaculty = response.faculties;
      } catch (e) {
        console.error(e);
      }
    },
    async getsemesters() {
      try {
        const response = await this.$http.post("/adminsemester/0");
        if (response.semesters.length == 0) {
          window.alert("Nem sikerült lekérni az adatokat.");
        } else {
          this.semesters = response.semesters;
        }
      } catch (e) {
        console.error(e);
      }
    },
    semesterChange() {
      this.listItems = [];
      this.listItems_en = [];
      this.search.type = "";
      this.search.department = "";
      this.search.faculty = "";
      // this.search.specialization = "";
      // this.search.samplePlan = "";
      this.samplePlanContent_hu = "";
      this.samplePlanContent_en = "";
      this.editor = true;
      this.lathato = this.search.semester.lathato == 1 ? true : false;
    },
    async typeChange() {
      this.listItems = [];
      this.listItems_en = [];
      this.search.department = "";
      this.search.faculty = "";
      // this.search.specialization = "";
      // this.search.samplePlan = "";
      this.samplePlanContent_hu = "";
      this.samplePlanContent_en = "";
      this.editor = true;
      if(this.search.type.value != 'Általános'){
        try {
          const post = this.search.type;
          const response = await this.$http.post("/getdepartments", post);
          this.department = response.departments;
        } catch (e) {
          console.error(e);
        }
      }
    },
    async departmentChange() {
      this.listItems = [];
      this.listItems_en = [];
      this.search.faculty = "";
      // this.search.department = "";
      // this.search.specialization = "";
      // this.search.samplePlan = "";
      this.samplePlanContent_hu = "";
      this.samplePlanContent_en = "";
      this.editor = true;
      if(this.search.type.value != 'Általános') {
        try {
          const post = this.search;
          const response = await this.$http.post("/getfaculties", post);
          this.faculty = response.faculties;
          if (response.faculties.length == 0) {
            this.snackbarColor = "red";
            this.snackbarText =
              "Ezekkel a keresési feltételekkel nem található mintatanterv";
            this.snackbar = true;
          }
        } catch (e) {
          console.error(e);
        }
      }/* else {

      }*/
    },
    async curicullumChange() {
      this.listItems = [];
      this.listItems_en = [];
      this.search.faculty = "";
      // this.search.specialization = "";
      // this.search.samplePlan = "";
      this.samplePlanContent_hu = "";
      this.samplePlanContent_en = "";
      this.editor = true;
      try {
        const post = this.search;
        const response = await this.$http.post("/getfaculties", post);
        this.faculty = response.faculties;
        if (response.faculties.length == 0) {
          this.snackbarColor = "red";
          this.snackbarText =
            "Ezekkel a keresési feltételekkel nem található mintatanterv";
          this.snackbar = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async facultyChange() {
      this.seeMessageCard = false;
      this.listItems = [];
      this.listItems_en = [];
      this.search.specialization = "";
      this.search.samplePlan = "";
      this.samplePlanContent_hu = "";
      this.editor = true;
      // try {
      //   // const post = this.search;
      //   const response = await this.$http.post(
      //     "/getspecializations",
      //     this.search
      //   );
      //   this.specialization = response.specializations;
      // } catch (e) {
      //   console.error(e);
      // }
    },
    async saveContent() {
      try {
        const editor_hu_text = tinymce
          .get("editor_hu")
          .plugins.wordcount.body.getCharacterCount();
        const editor_en_text = tinymce
          .get("editor_en")
          .plugins.wordcount.body.getCharacterCount();
        this.loadingCircular = true;
        this.saveContentDialog = false;
        let insert = true;
        if (this.alreadyHave) {
          insert = false;
        }
        if (this.search.type.value == "Általános") {
          this.search.faculty = {
            F3: "-",
          };
        }
        const post = {
          megjegyzes: this.megjegyzes,
          datas: this.search,
          Description_hu: this.samplePlanContent_hu,
          Description_en: this.samplePlanContent_en,
          insert: insert,
          lathato: this.lathato == true ? 1 : 0,
          editor_text_hu: editor_hu_text,
          editor_text_en: editor_en_text,
        };
        const response = await this.$http.post("/saveContent", post, {
          timeout: 0,
        });
        // setTimeout(() => (this.loadingCircular = false), 1500);
        // if (this.search.department.F1 == "-") {
        //   this.search = {
        //     semester: this.search.semester,
        //     type: "",
        //     department: "",
        //     faculty: "",
        //     specialization: "",
        //     samplePlan: "",
        //   };
        // }
        if (response.save == true) {
          this.snackbarText = "Sikeres mentés";
          this.snackbarColor = "green";
          this.listItems = [];
          this.listItems_en = [];
          this.samplePlanContent_hu = "";
          this.samplePlanContent_en = "";
          this.editor = true;
          this.megjegyzes = "";
        } else {
          this.snackbarText = "Sikertelen Mentés";
          this.snackbarColor = "red";
        }
        this.snackbar = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingCircular = false;
      }
    },

    async beforeSave() {
      try {
        if (this.search.type.value == "Általános") {
          this.search.faculty = {
            F3: "-",
          };
          this.search.specialization = {
            F7: "-",
          };
          this.search.samplePlan = {
            F10: "-",
            F9: "-",
          };
        }
        const response = await this.$http.post(
          "beforeSaveContent/",
          this.search
        );
        if (response.semesters) {
          this.alreadyHave = true;
        } else {
          this.alreadyHave = false;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.saveContentDialog = true;
      }
    },

    async saveNewSemester() {
      try {
        if (this.newSemesterType == "") {
          this.inputErrors = {
            type: "Kötelező választani típust",
          };
          return;
        }
        const post = {
          y1: this.newSemesterYear1,
          y2: this.newSemesterYear2,
          type: this.newSemesterType,
        };
        const response = await this.$http.post("/saveNewSemester", post);
        if (response.already_have == true) {
          this.snackbarText = "Ez a szemeszter már létezik";
          this.snackbarColor = "red";
        } else {
          this.snackbarText = "Sikeres mentés";
          this.snackbarColor = "green";
          this.newSemesterDialog = false;
        }
        this.getsemesters();
        this.snackbar = true;
        this.inputErrors = {};
      } catch (e) {
        console.error(e);
      }
    },

    async semester_dialog_open() {
      try {
        this.inputErrors = {};
        if (this.years.length == 0) {
          const response = await this.$http.post("/newSemesterDialogOpen");
          this.years = response.years;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.newSemesterDialog = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sticky {
  position: sticky;
  z-index: 5;
  top: 0;
  pointer-events: none;

  &.to-header {
    top: 64px;
  }
  & > * {
    pointer-events: auto;
  }
}
</style>