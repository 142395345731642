<template>
  <div>
    <Header
      :title="student[0].F0"
      :title_2="student[0].F1"
      :title_ne="lang.neptun_kod"
      :title_na="lang.nev"
    />
    <v-card elevation="2" class="mx-auto header-row mb-5 pa-0" width="80%">
      <v-row class="ma-0 pa-0">
        <v-col :cols="12" :lg="8" :md="8" :sm="12" class="d-flex align-center"
          ><v-autocomplete
            label="Mintatanterv"
            :items="studentModuls"
            item-text="Mintatanterv_nev"
            item-value="Mintatanterv_kod"
            return-object
            @change="changeMintatanterv"
            v-model="selectedMintatanterv"
            color="white"
            class="white--text mx-3 pa-0"
            item-color="uni_blue2"
            :flat="true"
            :hide-details="true"
            dark
          >
          </v-autocomplete
        ></v-col>
        <v-col :cols="12" :lg="4" :md="4" :sm="12">
          <v-btn
            @click="languageChange('hu')"
            class="mr-3 ml-0 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >hu</v-btn
          >
          <v-btn
            @click="languageChange('en')"
            class="mr-1 ml-3 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >en</v-btn
          >
          <v-btn
            elevation="0"
            color="white"
            class="mr-3 float-right"
            href="https://ttr.sze.hu/Shibboleth.sso/Logout"
          >
            <v-icon left>mdi-login</v-icon>
            {{ lang.kijelentkezes }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card width="80%" class="mx-auto mt-5">
      <v-row
        class="mx-auto text-center header-font ma-0 pa-0 pt-5 pb-5"
        justify="center"
      >
        <v-col
          :cols="12"
          :lg="2"
          :md="3"
          :sm="12"
          class="mx-2 border rounded d-flex align-center justify-center"
          ><v-btn color="transparent" elevation="0" @click="colorChange(0)">{{
            lang.alapallapot
          }}</v-btn></v-col
        >
        <v-col
          :cols="12"
          :lg="2"
          :md="3"
          :sm="12"
          class="mx-2 border rounded d-flex align-center justify-center"
          >{{ lang.tajekoztato_1 }}</v-col
        >
        <v-col
          :cols="12"
          :lg="2"
          :md="3"
          :sm="12"
          class="mx-2 border rounded d-flex align-center justify-center"
          ><p
            color="transparent"
            class="textflex ma-0 pa-0"
            @click="colorChange(1)"
          >
            {{ lang.felveheto_tantargyak }}
          </p></v-col
        >
        <v-col
          :cols="12"
          :lg="2"
          :md="3"
          :sm="12"
          class="mx-2 border rounded d-flex align-center justify-center"
          >{{ lang.tajekoztato_2 }}</v-col
        >
      </v-row>
      <v-row class="ma-0 pa-0 mx-6 text-body-2 text-center">
        <p class="ma-0 pa-0">
          <b>{{ lang.tajekoztato_cim1 }}</b> {{ lang.tajekoztato_szoveg1 }}
        </p>
        <p class="ma-0 pa-0">
          <b>{{ lang.tajekoztato_cim2 }}</b> {{ lang.tajekoztato_szoveg2 }}
        </p>
        <p class="ma-0 pa-0">
          <b>{{ lang.tajekoztato_cim3 }}</b> {{ lang.tajekoztato_szoveg3 }}
        </p>
      </v-row>
      <v-divider class="mx-4"></v-divider>
      <v-row class="ma-0 pa-0 mb-4">
        <v-col
          :cols="12"
          :lg="4"
          :md="12"
          :sm="12"
          class="pa-0 ma-0 text-center"
          >{{ lang.szak }}: <b v-if="langName == 'hu'">{{ information.F3 }}</b
          ><b v-else>{{ information.F16 }}</b></v-col
        >
        <v-col
          :cols="12"
          :lg="4"
          :md="12"
          :sm="12"
          class="pa-0 ma-0 text-center"
          >{{ lang.szakirany }}: <b>{{ information.F7 }}</b></v-col
        >
        <v-col
          :cols="12"
          :lg="4"
          :md="12"
          :sm="12"
          class="pa-0 ma-0 text-center"
          >{{ lang.tanterv }}: <b>{{ information.F10 }}</b></v-col
        >
      </v-row>
      <div class="nezetrow pb-0">
        <label class="switch">
          <input
            type="checkbox"
            class="checkbox"
            v-model="nezet"
            @change="changeNezet"
          />
          <div class="slider"></div>
        </label>
      </div>
      <div class="text-center">
        <p v-html="lang.nezet_1" class="text-caption"></p>
      </div>
      <v-row class="pa-0 ma-0" v-if="felevek.length > 0">
        <v-divider class="mx-5"></v-divider>
        <p class="w-100 text-center ma-0 pa-0 my-3">{{ lang.jelmagyarazat }}</p>
      </v-row>
      <v-row class="pa-0 mx-auto my-1 text-center d-flex justify-center" v-if="felevek.length > 0">
        <v-col :cols="12" :lg="2" :md="12" :sm="12">
          <div class="color-box-yellow"></div>
          {{ lang.kivalasztott_tantargy }}
        </v-col>
        <v-col :cols="12" :lg="2" :md="12" :sm="12"
          ><div class="color-box-darkblue"></div>
          -
          <div class="color-box-lightblue"></div>
          {{ lang.elokovetelmeny }}</v-col
        >
        <v-col :cols="12" :lg="2" :md="12" :sm="12"
          ><div class="color-box-green"></div>
          {{ lang.felveheto_tantargyak }}</v-col
        >
        <v-col :cols="12" :lg="2" :md="12" :sm="12"
          ><div class="color-box-darkred"></div>
          -
          <div class="color-box-lightred"></div>
          {{ lang.raepules }}</v-col
        >
        <v-col :cols="12" :lg="2" :md="12" :sm="12"
          ><div class="color-box-darkgreen"></div>
          {{ lang.teljesitett_targyak }}</v-col
        >
      </v-row>
      <v-simple-table class="ma-0 pa-0 mx-3 pb-3" v-if="felevek.length > 0">
        <thead class="trrelative">
          <tr>
            <th
              v-for="(ind, index) in felevek.length + 1"
              :key="index"
              class="border text-center m-0 p-0"
            >
              <div v-if="index == 0">
                <sub>{{ lang.kredit }} </sub>/<sup>
                  {{ lang.szak_keresese_ev }}</sup
                >
              </div>
              <div
                v-else
                :style="`padding: 1vh; background-color: ${
                  bgColors([
                    felevek[ind - 2].creditPerFelev -
                      felevek[ind - 2].nullCreditSub,
                    felevCredits[ind - 2],
                  ])[0]
                }; color:${
                  bgColors([
                    felevek[ind - 2].creditPerFelev -
                      felevek[ind - 2].nullCreditSub,
                    felevCredits[ind - 2],
                  ])[1]
                }`"
              >
                <div class="text-center">
                  {{ ind - 1 }}
                </div>
                <div>
                  {{
                    felevek[ind - 2].creditPerFelev -
                    felevek[ind - 2].nullCreditSub
                  }}
                  / {{ felevCredits[ind - 2] }}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr v-for="credit in maxRow" :key="credit">
            <td class="border">{{ credit }}</td>
            <template v-for="felev in felevek">
              <template v-for="f in felev.subs">
                <td
                  class="border"
                  :key="f.Targynev"
                  v-if="f.eddigi_kredit + 1 == credit"
                  :rowspan="f.Targy_kredit != 0 ? f.Targy_kredit : 1"
                  :style="`background-color: ${f.color}; color: ${f.text_color};`"
                  @click="selectSubject(f)"
                  @drop="onDrop($event, felev.sem)"
                  @dragover.prevent
                  @dragenter.prevent
                  draggable="true"
                  @dragstart="startDrag($event, f)"
                >
                  <v-tooltip
                    bottom
                    :key="f.Targynev"
                    color="uni_blue"
                    close-delay="500"
                    open-delay="500"
                    :style="`color: ${f.text_color}`"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        href="javascript:void(0)"
                        :style="`color: ${f.text_color}`"
                        v-bind="attrs"
                        v-on="on"
                        @click="tematikaMegtekintes(f.Targykod)"
                        v-if="Number.isNaN(Number.parseFloat(f.Targynev))"
                        >{{ f.Targynev }}<br />{{ f.Targykod }}</a
                      >
                      <input
                        type="checkbox"
                        :id="f.Targykod"
                        class="text-warning mx-2 pt-2"
                        v-if="Number.isNaN(Number.parseFloat(f.Targynev))"
                        @click="countCredits(f, -1)"
                        :disabled="f.done"
                      />
                    </template>
                    <p class="m-0 p-0">
                      {{ lang.table_megnevezes }}: <span>{{ f.Targynev }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_beszamolas_fajtaja }}: <span>Vizsga</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_kreditpont }}:
                      <span>{{ f.Targy_kredit }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_ajanlott_felev }}:
                      <span>{{ f.Felev_szam }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.teljesitve }}:
                      <span>{{ f.mikor == "soha" ? lang.soha : f.mikor }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_elotanulmanyi_kov }}:
                      <span>{{ f.Elokovetelmeny_leiras_1 }}</span
                      ><br /><span>{{ f.Elokovetelmeny_leiras_2 }}</span>
                    </p>
                  </v-tooltip>
                </td>
              </template>
            </template>
          </tr>
          <tr class="border">
            <td
              v-for="(fel, ind) in felevek.length + 1"
              :key="ind"
              class="border p-0 m-0"
            >
              <div v-if="ind == 0">
                {{ lang.kredit }}
              </div>
              <div
                v-else
                :style="`padding: 1vh; background-color: ${
                  bgColors([
                    felevek[fel - 2].creditPerFelev -
                      felevek[fel - 2].nullCreditSub,
                    felevCredits[fel - 2],
                  ])[0]
                }; color:${
                  bgColors([
                    felevek[fel - 2].creditPerFelev -
                      felevek[fel - 2].nullCreditSub,
                    felevCredits[fel - 2],
                  ])[1]
                }`"
              >
                <div class="text-center">
                  {{ fel - 1 }}
                </div>
              </div>
            </td>
          </tr>
          <tr class="border">
            <td
              class="text-center"
              :colspan="felevek.length + 1"
              :style="`font-size: 20px !important; background-color: ${footerColor[0]}; color: ${footerColor[1]}`"
            >
              {{ lang.kotelelezo_tantargyak_ossz_kredit }}
              <b>{{ allCredit - allNullCreditSub }}</b> /
              <b>{{ getAllCredit }}</b>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <v-card width="80%" class="mx-auto ma-0 pa-0 my-3">
      <p
        class="display-1 text-center font-weight-bold pa-0 py-3"
        v-if="felevek.length > 0"
      >
        {{ lang.tantargycsoportok }}
      </p>
      <v-row class="mx-auto ma-0 pa-0">
        <v-col
          :cols="12"
          :lg="6"
          :md="12"
          v-for="(types, index) in types"
          :key="index"
          class="pa-0 ma-0"
        >
          <v-card v-if="!types.name.includes('Kötelező tárgyak')" class="pa-0 mx-3 my-2" elevation="2">
            <v-row class="pa-0 ma-0 font-weight-bold">
              <v-col :cols="12" :lg="6" :md="6" :sm="6">{{ types.name }}</v-col>
              <v-col
                :cols="12"
                :lg="6"
                :md="6"
                :sm="6"
                v-if="types.credit != null"
                >{{ lang.teljesitendo_kredit }}: {{ types.credit }}</v-col
              >
              <v-col
                :cols="12"
                :lg="6"
                :md="6"
                :sm="6"
                v-else-if="types.tantargyak_szama != null"
                >{{ lang.elvegzett_tantargy_csoport }}
                {{ types.tantargyak_szama }}</v-col
              >
            </v-row>
            <p class="pa-0 ma-0 px-2 py-2 caption">
              {{ types.leiras }}
            </p>
            <v-simple-table class="pa-1" v-if="types.children.length == 0">
              <thead v-if="types.credit != null" class="border-top rounded">
                <tr class="rounded">
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_sorszam }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_megnevezes }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_kod }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.teljesitve }}
                  </th>
                </tr>
              </thead>
              <thead
                v-else-if="types.tantargyak_szama != null"
                class="border-top rounded"
              >
                <tr class="rounded">
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_sorszam }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_megnevezes }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_kod }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.teljesitve }}
                  </th>
                </tr>
              </thead>
              <thead
                v-else-if="
                  types.credit == null && types.tantargyak_szama == null
                "
                class="border-top rounded"
              >
                <tr class="rounded">
                  <th>{{ lang.table_sorszam }}</th>
                  <th>{{ lang.table_megnevezes }}</th>
                  <th>{{ lang.table_kod }}</th>
                  <th>{{ lang.teljesitve }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(sub, id) in types.subjects"
                  :key="id"

                >
                  <td >{{ id + 1 }}</td>
                  <v-tooltip
                    bottom
                    :key="sub.Targykod"
                    color="uni_blue"
                    close-delay="500"
                    open-delay="500"
                    :style="`color: ${sub.text_color}`"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <td
                        class="border"
                        v-bind="attrs"
                        v-on="on"
                        @click="selectSubject(sub)"
                        :style="`background-color: ${sub.color}; color: ${sub.text_color};`"
                      >
                        <a
                          href="javascript:void(0)"
                          @click="tematikaMegtekintes(sub.Targykod)"
                          :style="`color: ${sub.text_color};`"
                          >{{ sub.Targynev }}</a
                        >
                      </td>
                    </template>
                    <p class="m-0 p-0">
                      {{ lang.table_megnevezes }}:
                      <span>{{ sub.Targynev }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_beszamolas_fajtaja }}: <span>Vizsga</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_kreditpont }}:
                      <span>{{ sub.Targy_kredit }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_ajanlott_felev }}:
                      <span>{{ sub.Felev_szam }}</span>
                    </p>
                    <p class="m-0 p-0">
                      Mikor:
                      <span>{{
                        sub.mikor == "soha" ? lang.soha : sub.mikor
                      }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_elotanulmanyi_kov }}:
                      <span>{{ sub.Elokovetelmeny_leiras_1 }}</span
                      ><br /><span>{{ sub.Elokovetelmeny_leiras_2 }}</span>
                    </p>
                  </v-tooltip>
                  <td class="">{{ sub.Targykod }}</td>
                  <td class="align-content-center">
                    <input
                      class="my-1 mx-auto align-content-center w-100"
                      type="checkbox"
                      name=""
                      :id="sub.Targykod"
                      @click="countCredits(sub, index)"
                      :disabled="sub.done"
                    />
                  </td>
                </tr>
                <tr>
                  <!-- :style="`font-size: 20px !important; background-color: ${footerColor[0]}; color: ${footerColor[1]}`" -->
                  <td
                    class="text-center rounded"
                    :colspan="4"
                    v-if="types.credit != null"
                    :style="`padding: 1vh; background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.kotelelezo_tantargyak_ossz_kredit }}
                    <b>{{ types.credit }}</b> /
                    <b>{{ getSecondAllCredit[index].credit }}</b>
                  </td>
                  <td
                    class="text-center rounded"
                    :colspan="4"
                    v-if="types.tantargyak_szama != null"
                    :style="`padding: 1vh; background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.elvegzett_tantargy_csoport }}
                    <b>{{ types.tantargyak_szama }}</b> /
                    <b>{{ getSecondAllCredit[index].db }}</b>
                  </td>
                  <td
                    class="text-center rounded"
                    :colspan="4"
                    v-if="types.name.includes('Testnevelés')"
                    :style="`padding: 1vh; background-color: ${
                      bgColors([
                        types.subjects.length,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.subjects.length,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.elvegzett_osszes_tantargy }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div v-else>
              <div v-for="(child, childKey) in types.children" :key="childKey">
                <p class="mx-2 mt-3 font-weight-bold">
                  <b>{{ child.name }}</b>
                </p>
                <p class="mx-2">
                  {{ child.leiras }}
                </p>
                <v-simple-table class="mx-2">
                  <thead v-if="types.credit != null" class="border-top rounded">
                    <tr>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_sorszam }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_megnevezes }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_kod }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[1]
                        }`"
                      >
                        {{ lang.teljesitve }}
                      </th>
                    </tr>
                  </thead>
                  <thead
                    v-if="types.tantargyak_szama != null"
                    class="border-top rounded"
                  >
                    <tr>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_sorszam }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_megnevezes }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_kod }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[1]
                        }`"
                      >
                        {{ lang.teljesitve }}
                      </th>
                    </tr>
                  </thead>
                  <thead
                    v-if="
                      types.credit == null && types.tantargyak_szama == null
                    "
                    class="border-top rounded"
                  >
                    <tr>
                      <td>{{ lang.table_sorszam }}</td>
                      <td>{{ lang.table_megnevezes }}</td>
                      <td>{{ lang.table_kod }}</td>
                      <td>{{ lang.teljesitve }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sub, id) in child.subjects" :key="id">
                      <td>{{ id + 1 }}</td>
                      <v-tooltip
                        bottom
                        :key="sub.Targykod"
                        color="uni_blue"
                        close-delay="500"
                        open-delay="500"
                        :style="`color: ${sub.text_color}`"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <td
                            class="border"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectSubject(sub)"
                            :style="`background-color: ${sub.color}; color: ${sub.text_color};`"
                          >
                            <a
                              href="javascript:void(0)"
                              @click="tematikaMegtekintes(sub.Targykod)"
                              :style="`color: ${sub.text_color};`"
                              >{{ sub.Targynev }}</a
                            >
                          </td>
                        </template>
                        <p class="m-0 p-0">
                          {{ lang.table_megnevezes }}:
                          <span>{{ sub.Targynev }}</span>
                        </p>
                        <p class="m-0 p-0">
                          {{ lang.table_beszamolas_fajtaja }}:
                          <span>Vizsga</span>
                        </p>
                        <p class="m-0 p-0">
                          {{ lang.table_kreditpont }}:
                          <span>{{ sub.Targy_kredit }}</span>
                        </p>
                        <p class="m-0 p-0">
                          {{ lang.table_ajanlott_felev }}:
                          <span>{{ sub.Felev_szam }}</span>
                        </p>
                        <p class="m-0 p-0">
                          Mikor:
                          <span>{{
                            sub.mikor == "soha" ? lang.soha : sub.mikor
                          }}</span>
                        </p>
                        <p class="m-0 p-0">
                          {{ lang.table_elotanulmanyi_kov }}:
                          <span>{{ sub.Elokovetelmeny_leiras_1 }}</span
                          ><br /><span>{{ sub.Elokovetelmeny_leiras_2 }}</span>
                        </p>
                      </v-tooltip>
                      <td >{{ sub.Targykod }}</td>
                      <td class=" align-content-center">
                        <input
                          class="my-1 mx-auto align-content-center w-100"
                          type="checkbox"
                          name=""
                          :id="sub.Targykod"
                          @click="countCredits(sub, index)"
                          :disabled="sub.done"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
              <div>
                <div
                  class="text-center rounded"
                  :colspan="4"
                  v-if="types.credit != null"
                  :style="`padding: 1vh; background-color: ${
                    bgColors([
                      types.credit,
                      getSecondAllCredit[index].credit,
                    ])[0]
                  }; color: ${
                    bgColors([
                      types.credit,
                      getSecondAllCredit[index].credit,
                    ])[1]
                  }`"
                >
                  {{ lang.kotelelezo_tantargyak_ossz_kredit }}
                  <b>{{ types.credit }}</b
                  >asdasdasd /
                  <b>{{ getSecondAllCredit[index].credit }}</b>
                </div>
                <div
                  class="text-center rounded"
                  :colspan="4"
                  v-if="types.tantargyak_szama != null"
                  :style="`padding: 1vh; background-color: ${
                    bgColors([
                      types.tantargyak_szama,
                      getSecondAllCredit[index].db,
                    ])[0]
                  }; color: ${
                    bgColors([
                      types.tantargyak_szama,
                      getSecondAllCredit[index].db,
                    ])[1]
                  }`"
                >
                  {{ lang.elvegzett_tantargy_csoport }}
                  <b>{{ types.tantargyak_szama }}</b> /
                  <b>{{ getSecondAllCredit[index].db }}</b>
                </div>
                <div
                  class="text-center rounded"
                  :colspan="4"
                  v-if="types.name.includes('Testnevelés')"
                  :style="`padding: 1vh; background-color: ${
                    bgColors([
                      types.subjects.length,
                      getSecondAllCredit[index].db,
                    ])[0]
                  }; color: ${
                    bgColors([
                      types.subjects.length,
                      getSecondAllCredit[index].db,
                    ])[1]
                  }`"
                >
                  {{ lang.elvegzett_osszes_tantargy }}
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" v-if="felevek.length > 0">
        <v-divider class="mx-5"></v-divider>
        <p class="display-1 w-100 text-center ma-0 pa-0 my-3">{{ lang.jelmagyarazat }}</p>
      </v-row>
      <v-row class="pa-0 mx-auto my-3 text-center d-flex justify-center" v-if="felevek.length > 0">
        <v-col :cols="12" :lg="2" :md="12" :sm="12">
          <div class="color-box-yellow"></div>
          {{ lang.kivalasztott_tantargy }}
        </v-col>
        <v-col :cols="12" :lg="2" :md="12" :sm="12"
          ><div class="color-box-darkblue"></div>
          -
          <div class="color-box-lightblue"></div>
          {{ lang.elokovetelmeny }}</v-col
        >
        <v-col :cols="12" :lg="2" :md="12" :sm="12"
          ><div class="color-box-green"></div>
          {{ lang.felveheto_tantargyak }}</v-col
        >
        <v-col :cols="12" :lg="2" :md="12" :sm="12"
          ><div class="color-box-darkred"></div>
          -
          <div class="color-box-lightred"></div>
          {{ lang.raepules }}</v-col
        >
        <v-col :cols="12" :lg="2" :md="12" :sm="12"
          ><div class="color-box-darkgreen"></div>
          {{ lang.teljesitett_targyak }}</v-col
        >
      </v-row>
    </v-card>
    <div class="text-center">
      <v-dialog v-model="dialog" width="60%">
        <v-card>
          <v-card-text>
            <TantargyTematika :subject="subject" :lang="lang" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-overlay :value="loadingCircular" opacity="0.9">
      <v-progress-circular
        indeterminate
        size="250"
        width="10"
        color="uni_blue2"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import TantargyTematika from "@/components/TantargyTematika.vue";
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
export default {
  components: {
    TantargyTematika,
    Header,
  },
  data: () => ({
    lang: en,
    langName: "hu",
    dialog: false,
    loadingCircular: false,
    subjects: [],
    subject: {},
    felevek: [],
    allSubjects: [],
    maxRow: 0,
    felevCredits: [],
    information: {},
    allCredit: 0,
    getAllCredit: 0,
    felevekAlap: [],
    maxRowAlap: 0,
    utokovetelmenyColors: [
      "#3c0000",
      "#a20000",
      "#ff0909",
      "#ff6f6f",
      "#EC407A",
      "#f38caf",
      "#f8bbd0",
      "#fce8ef",
    ],
    elokovetelmenyColors: [
      "#061d30",
      "#135a91",
      "#1a78c2",
      "#1d87da",
      "#63b5f6",
      "#90caf9",
      "#bcdffb",
      "#e8f4fd",
    ],
    allCreditColor: ["white", "black"],
    freeSubsCount: 0,
    types: [],
    getSecondAllCredit: [],
    allNullCreditSub: 0,
    student: [
      {
        F0: "",
        F1: "",
      },
    ],
    studentModuls: [],
    selectedMintatanterv: {
      Modulkod: "",
      Mintatanterv_kod: "",
      Mintatanterv_nev: "",
    },
    getSecondAllSubjects: [],
    nezet: false,
  }),
  async mounted() {
    if (this.$route.params.lang == "hu") {
      this.langName = "hu";
      this.lang = hu;
    } else {
      this.langName = "en";
      this.lang = en;
    }
    this.getDatas();
    if (this.information.length == 0) {
      this.$router.push("/home").catch(() => {});
    }
  },
  computed: {
    bgColors() {
      return (numbers) => [
        `${
          numbers[0] <= numbers[1] && numbers[0] != null && numbers[0] > 0
            ? "#43A047"
            : "white"
        }`,
        `${
          numbers[0] <= numbers[1] && numbers[0] != null && numbers[0] > 0
            ? "white"
            : "black"
        }`,
      ];
    },

    footerColor() {
      if (this.allCredit == this.getAllCredit) {
        return ["#43A047", "white"];
      } else {
        return ["white", "black"];
      }
    },
  },
  methods: {
    changeNezet() {
      this.changeMintatanterv(this.nezet);
    },
    changeMintatanterv(nezet) {
      var datas = {
        mintaterv: {
          Modulkod: this.selectedMintatanterv.Modulkod,
          Mintatanterv_kod: this.selectedMintatanterv.Mintatanterv_kod,
          nezet: nezet,
        },
      };
      this.getDatas(datas);
    },
    languageChange(type) {
      if (type == "en") {
        this.lang = en;
        this.langName = "en";
      } else {
        this.lang = hu;
        this.langName = "hu";
      }
      this.$cookies.set("lang", type);
    },

    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.Targykod);
      var itemJson = JSON.stringify(item);
      evt.dataTransfer.setData("item", itemJson);
    },

    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const firstItem = JSON.parse(evt.dataTransfer.getData("item"));
      //favágó megoldás arra a problémára ha több tantárgy ki van pipálva, a választott előtt lévőket nem pipálja vissza az utána lévőket viszont igen.
      if (!firstItem.done) {
        var tantargyak = this.subjects.subjects;
        var cb = document.getElementById(firstItem.Targykod);

        this.felevek[parseInt(firstItem.Felev_szam) - 1].subs.forEach(
          (element) => {
            if (Number.isNaN(Number.parseFloat(element.Targykod))) {
              var cb = document.getElementById(element.Targykod);
              if (cb.checked == true && firstItem.Felev_szam != list) {
                this.felevCredits[firstItem.Felev_szam - 1] -= parseInt(
                  element.Targy_kredit
                );
                this.getAllCredit -= parseInt(element.Targy_kredit);
                cb.checked = false;
              }
            }
          }
        );
        // if(cb.checked == true && firstItem.Felev_szam != list) {
        //   this.felevCredits[firstItem.Felev_szam-1] -= parseInt(firstItem.Targy_kredit)
        //   this.getAllCredit -= parseInt(firstItem.Targy_kredit)
        //   cb.checked = false;
        // }
        var t = [];
        t[0] = firstItem;
        var szamlalo = 0;
        var van = true;
        var osszes = [];
        while (van == true) {
          szamlalo++;
          var ret = this.utoKovetelmenyek(t, tantargyak, szamlalo);
          van = ret.van;
          t = ret.utokov;
          ret.utokov.forEach((element) => {
            osszes.push(element);
          });
        }
        this.felevek.forEach((item) => {
          item.subs.forEach((element) => {
            if (element.done == false) {
              element.color = "white";
              element.text_color = "#038cac";
            } else {
              if (Number.isNaN(Number.parseFloat(element.Targykod)))
                element.color = "#18960c";
              element.text_color = "#white";
            }
          });
        });
        osszes.forEach((item) => {
          this.felevek.forEach((element) => {
            element.subs.forEach((value) => {
              if (firstItem.Targykod == value.Targykod) {
                value.color = "#FB8C00";
                value.text_color = "white";
              }
              // if(item.F2 == value.F2) {
              //   value.color = this.utokovetelmenyColors[item.hanyadik-1];
              //   value.text_color = "white"
              // }
            });
          });
        });
        if (
          firstItem.Felev_szam.toString() != list.toString() &&
          parseInt(firstItem.Felev_szam) < parseInt(list)
        ) {
          this.csere(itemID, list, osszes);
        } else if (
          firstItem.Felev_szam.toString() != list.toString() &&
          parseInt(firstItem.Felev_szam) > parseInt(list)
        ) {
          osszes = [];
          this.csere(itemID, list, osszes);
        }
      }
    },

    async getDatas(datas) {
      try {
        this.subjects = [];
        this.subject = {};
        this.felevek = [];
        this.allSubjects = [];
        this.maxRow = 0;
        this.felevCredits = [];
        this.information = {};
        this.allCredit = 0;
        this.getAllCredit = 0;
        this.felevekAlap = [];
        this.maxRowAlap = 0;
        this.freeSubsCount = 0;
        this.types = [];
        this.getSecondAllCredit = [];
        this.allNullCreditSub = 0;
        this.types = [];
        this.loadingCircular = true;
        var post = {
          mintaterv: {
            Modulkod: this.$route.params.modul_kod,
            Mintatanterv_kod: this.$route.params.minta_kod,
            nezet: this.nezet,
          },
        };
        if (datas != undefined && datas.mintaterv.Modulkod != "") {
          post = datas;
        }
        const response = await this.$http.post("userMatrix/", post, {
          timeout: 0,
        });

        this.information = response.information[0];
        this.felevek = response.felevek;
        this.student = response.student;
        this.felevekAlap = this.felevek;
        this.subjects = response.types[0];
        this.allSubjects = response.types;
        this.studentModuls = response.student_moduls;
        response.types.forEach((element, index) => {
          if (index > 0) {
            this.types.push(element);
          }
        });

        var countAllCredit = 0;
        this.felevek.forEach((element) => {
          this.allCredit += element.creditPerFelev;
          var credit = 0;
          element.subs.forEach((item) => {
            if (item.done) {
              credit += parseInt(item.Targy_kredit);
              countAllCredit += parseInt(item.Targy_kredit);
            }
          });
          this.felevCredits.push(credit);
          if (element.nullCreditSub != 0) {
            this.allNullCreditSub += element.nullCreditSub;
          }
        });
        this.getAllCredit = countAllCredit;
        response.types.forEach((element, id) => {
          var credit = {
            credit: 0,
            db: 0,
          };
          if (id > 0) {
            element.subjects.forEach((item) => {
              if (item.done) {
                credit.credit += parseInt(item.Targy_kredit);
                credit.db++;
              }
            });
            this.getSecondAllCredit.push(credit);
          }
        });
        this.maxRow = Math.max.apply(
          Math,
          response.felevek.map(function (o) {
            return o.creditPerFelev;
          })
        );
        this.maxRowAlap = this.maxRow;
        setTimeout(() => (this.loadingCircular = false), 500);
      } catch (e) {
        console.error(e);
      }
    },

    csere(code, felev_szam, utokovetelmenyek) {
      //mit, hova, mik az utókövetelmények
      var eredeti = this.subjects.subjects; //kötelező tantárgyak
      var tantargy = {};
      eredeti.forEach((item) => {
        if (item.Targykod == code && item.Felev_szam != felev_szam) {
          item.Felev_szam = felev_szam;
          tantargy = item;
        }
      });

      var felevekk = [];
      var felevekSeged = [];

      utokovetelmenyek.forEach((element) => {
        eredeti.forEach((item) => {
          if (
            element.Targykod == item.Targykod &&
            parseInt(element.Felev_szam) - parseInt(tantargy.Felev_szam) <
              parseInt(element.hanyadik)
          ) {
            var seged =
              parseInt(tantargy.Felev_szam) + parseInt(element.hanyadik);
            item.Felev_szam = seged.toString();
          }
        });
      });

      //this.subjects.length az az eredeti félévszám
      eredeti.forEach((item) => {
        if (!felevekSeged.includes(parseInt(item.Felev_szam))) {
          felevekSeged.push(parseInt(item.Felev_szam));
        }
      });
      this.subjects.subjects = eredeti;
      felevekSeged.sort(function (a, b) {
        return a - b;
      });

      if (this.felevek.length > felevekSeged.length) {
        felevekSeged = [];

        this.felevek.forEach((element) => {
          felevekSeged.push(element.sem);
        });
      }

      var allCredits = 0;
      felevekSeged.forEach((item) => {
        var felev = {};
        felev.sem = "";
        felev.creditPerFelev = 0;
        felev.subs = [];
        felev.sem = item.toString();
        allCredits = 0;
        var nullCreditSub = 0;
        eredeti.forEach((element) => {
          if (element.Felev_szam == item.toString()) {
            element.eddigi_kredit = allCredits;
            if (parseInt(element.Targy_kredit) == 0) {
              allCredits += 1;
              nullCreditSub += 1;
            } else {
              allCredits += parseInt(element.Targy_kredit);
            }
            felev.subs.push(element);
          }
        });
        felev.nullCreditSub = nullCreditSub;
        felev.creditPerFelev = allCredits;
        felevekk.push(felev);
      });
      var maxRoww = 0;
      maxRoww = parseInt(
        Math.max.apply(
          Math,
          felevekk.map(function (o) {
            return o.creditPerFelev;
          })
        )
      );
      felevekk.forEach((item) => {
        var plusz = {};
        plusz.Targykod = item.sem;
        plusz.Targynev = Math.floor(Math.random() * 1000);
        plusz.Felev_szam = item.sem;
        plusz.Targy_kredit = parseInt(maxRoww) - parseInt(item.creditPerFelev);
        plusz.eddigi_kredit = parseInt(item.creditPerFelev);
        item.subs.push(plusz);
      });
      let countFelevCredits = this.felevCredits.length;
      for (let index = countFelevCredits; index < maxRoww; index++) {
        this.felevCredits.push(0);
      }
      this.felevek = felevekk;
      this.maxRow = maxRoww;
    },

    async tematikaMegtekintes(F2) {
      try {
        const post = {
          sub: F2,
        };
        const response = await this.$http.post("getSubjectDetails/", post);
        this.subject = response.details;
      } catch (e) {
        console.error(e);
      }
      this.dialog = true;
    },

    colorChange(type) {
      //Ez a FreeSubjects részre vonatkozik
      this.felevek.forEach((item) => {
        item.subs.forEach((element) => {
          if (!element.done) {
            element.color = "white";
            element.text_color = "#038cac";
          }
        });
      });
      if (type == 1) {
        if (this.freeSubsCount % 2 == 0) {
          this.felevek.forEach((item) => {
            item.subs.forEach((element, id) => {
              if (
                element.Elokovetelmeny_leiras_1 == null &&
                element.Elokovetelmeny_leiras_2 == null &&
                Number.isNaN(Number.parseFloat(element.Targykod)) &&
                !element.done
              ) {
                element.color = "#81C784";
                element.text_color = "white";
              }
              if (
                element.done == false &&
                element.elokov &&
                element.elokov.length > 0
              ) {
                var lehet = true;
                element.elokov.forEach((value) => {
                  item.subs.forEach((val) => {
                    if (val.Targykod == value.Targykod && val.done == false) {
                      lehet = false;
                    }
                  });
                });
                if (lehet == true) {
                  element.color = "#81C784";
                  element.text_color = "white";
                }
              }
            });
          });
        } else {
          this.felevek.forEach((item) => {
            item.subs.forEach((element, id) => {
              if (!element.done) {
                element.color = "white";
                element.text_color = "#038cac";
              }
            });
          });
        }
        this.freeSubsCount++;
      } else if (type == 0) {
        this.felevek = [];
        this.felevek = this.felevekAlap;
        this.maxRow = this.maxRowAlap;
        this.felevek.forEach((item) => {
          item.subs.forEach((element) => {
            if (!element.done) {
              element.color = "white";
              element.text_color = "#038cac";
            }
          });
        });
      }
    },

    countCredits(f, index) {
      var cb = document.getElementById(f.Targykod);
      if (index == -1) {
        if (cb.checked == true) {
          this.felevCredits[f.Felev_szam - 1] += parseInt(f.Targy_kredit);
          this.getAllCredit += parseInt(f.Targy_kredit);
        } else {
          this.felevCredits[f.Felev_szam - 1] -= parseInt(f.Targy_kredit);
          this.getAllCredit -= parseInt(f.Targy_kredit);
        }
      } else {
        if (cb.checked == true) {
          this.getSecondAllCredit[index].credit += parseInt(f.Targy_kredit);
          this.getSecondAllCredit[index].db++;
        } else {
          this.getSecondAllCredit[index].credit -= parseInt(f.Targy_kredit);
          this.getSecondAllCredit[index].db--;
        }
      }
      this.$forceUpdate();
    },

    selectSubject(tantargy) {
      this.felevek.forEach((item) => {
        item.subs.forEach((element) => {
          if (!element.done) {
            this.$set(element, "color", "white");
            this.$set(element, "text_color", "#038cac");
          } else {
            this.$set(element, "color", "#18960c");
            this.$set(element, "text_color", "white");
          }
        });
      });

      this.types.forEach((item) => {
        item.subjects.forEach((element) => {
          if (!element.done) {
            this.$set(element, "text_color", "#038cac");
            this.$set(element, "color", "white");
          } else {
            this.$set(element, "text_color", "white");
            this.$set(element, "color", "#18960c");
          }
        });
      });
      if (Number.isNaN(Number.parseFloat(tantargy.Targykod))) {
        tantargy.color = "#FB8C00";
        tantargy.text_color = "white";
      }

      var tantargyak = [];
      var t = [];
      var tSeged = tantargy;
      t[0] = tantargy;
      var szamlalo = 0;
      var elokovetelmenyek = [];
      var van = true;
      var osszes = [];
      var osszesUto = [];
      this.allSubjects.forEach((item) => {
        item.subjects.forEach((element) => {
          tantargyak.push(element);
        });
      });
      while (van == true) {
        szamlalo++;
        var retU = this.utoKovetelmenyek(t, tantargyak, szamlalo);
        van = retU.van;
        t = retU.utokov;
        retU.utokov.forEach((element) => {
          osszesUto.push(element);
        });
      }
      osszesUto.forEach((item) => {
        this.felevek.forEach((element) => {
          element.subs.forEach((value) => {
            if (item.Targykod == value.Targykod) {
              value.color = this.utokovetelmenyColors[item.hanyadik - 1];
              value.text_color = "white";
            }
          });
        });
        this.types.forEach((element) => {
          element.subjects.forEach((value) => {
            if (item.Targykod == value.Targykod) {
              value.color = this.utokovetelmenyColors[item.hanyadik - 1];
              value.text_color = "white";
            }
          });
        });
      });
      t = [];
      t[0] = tSeged;
      szamlalo = 0;
      elokovetelmenyek = [];
      van = true;
      if ("elokov" in tantargy) {
        if (tantargy.elokov.length > 0) {
          while (van == true) {
            szamlalo++;
            var ret = this.elokovetelmenyekFunction(
              t,
              tantargyak,
              szamlalo,
              osszes
            );
            t = ret.elokov;
            t.forEach((item) => {
              osszes.push(item);
            });
            van = ret.talalat;
          }
          osszes.forEach((item) => {
            this.felevek.forEach((element) => {
              element.subs.forEach((value) => {
                if (item.Targykod == value.Targykod) {
                  value.color = this.elokovetelmenyColors[item.hanyadik - 1];
                  value.text_color = "white";
                }
              });
            });
            this.types.forEach((element) => {
              element.subjects.forEach((value) => {
                if (item.Targykod == value.Targykod) {
                  value.color = this.elokovetelmenyColors[item.hanyadik - 1];
                  value.text_color = "white";
                }
              });
            });
          });
        }
      }

      this.$forceUpdate();
    },

    elokovetelmenyekFunction(t, tantargyak, szamlalo, osszes) {
      var ret = {};
      var osszelokov = [];
      var talalat = false;
      var elokov = [];
      t.forEach((element) => {
        element.elokov.forEach((item) => {
          tantargyak.forEach((value) => {
            if (item == value.Targykod) {
              var plusz = {};
              plusz = value;
              plusz.hanyadik = szamlalo;
              talalat = true;
              plusz.raepul = element.Targykod;
              elokov.push(plusz);
            }
          });
        });
      });
      ret.elokov = elokov;
      ret.talalat = talalat;
      return ret;
    },

    utoKovetelmenyek(t, tantargyak, szamlalo) {
      var ret = {};
      ret.van = false;
      ret.utokov = [];
      t.forEach((element) => {
        tantargyak.forEach((item) => {
          item.elokov.forEach((value) => {
            if (value == element.Targykod) {
              ret.van = true;
              ret.utokov.push(item);
              item.hanyadik = szamlalo;
            }
          });
        });
      });

      return ret;
    },
  },
};
</script>
<style scoped>
td {
  font-size: 12px !important;
  height: 20px !important;
  min-width: 150px !important;
}
.btn {
  font-size: 11px !important;
}
.header-font {
  font-size: 12px;
}
a {
  text-decoration: none;
  color: #038cac;
}

.v-icon::hover {
  color: transparent;
}

.v-icon::after {
  color: transparent;
}
.jelmagy {
  width: 90%;
  text-align: center;
  margin: auto;
}
.textflex {
  cursor: pointer;
  font-size: 1rem;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align: center;
}
.color-box-yellow {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #fb8c00;
}
.color-box-darkblue {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #061d30;
}
.color-box-lightblue {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #e8f4fd;
}
.color-box-darkred {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #3c0000;
}
.color-box-lightred {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #fce8ef;
}
.color-box-green {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #81c784;
}
.color-box-darkgreen {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #18960c;
}

/* .trrelative {
  position: relative;
} */
.nezetrow {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
}

.checkbox {
  display: none;
}

.slider {
  width: 8rem;
  height: 2rem;
  background-color: lightgray;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  transition: 0.3s;
  box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
  cursor: pointer;
}

.slider::before {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "Nézet 1";
  width: 80%;
  height: 100%;
  background-color: #fff;
  /* transform: translateX(-30px); */
  border-radius: 100px;
  transition: 0.3s;
  box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkbox:checked ~ .slider::before {
  transform: translateX(30px);
  box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
  content: "Nézet 2";
}

.checkbox:checked ~ .slider {
  background-color: #00adcb;
}
.header-row {
  background: linear-gradient(135deg, #00adcb 20%, #0c3c60);
}
.header-text {
  font-size: 1.5rem;
  font-weight: 600;
}
.textflex {
  cursor: pointer;
  font-size: 1rem;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align: center;
}
</style>

/*
  - Ha shiftelem a types-ot akkor kiveszi az allsubjectsből is
*/
